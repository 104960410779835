import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueMask from '@devindex/vue-mask'

import { GDialog, plugin as dialogPlugin } from 'gitart-vue-dialog'
import 'gitart-vue-dialog/dist/style.css'

const app = createApp(App)
app
  .use(router)
  .use(store)
  .use(dialogPlugin)
  .use(VueMask)
  .component('GDialog', GDialog)
  .mount('#app')
