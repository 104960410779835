import { createStore } from 'vuex'

import * as getters from '@/store/getters'
import * as actions from '@/store/actions.js'
import * as mutations from '@/store/mutations'

import toaster from '@/store/modules/toaster.js'
import session from '@/store/modules/session.js'
import screen from '@/store/modules/screen.js'

const state = {
  updatePending: 0,
}

export default createStore({
  modules: {
    toaster,
    session,
    screen,
  },
  state,
  getters,
  mutations,
  actions,
})
