//import router from '@/router'
import { apiGetData } from '@/services/ApiService.js'
import { Buffer } from 'buffer'

const state = () => ({
  rememberMe: false,
  auth: false,
  sysHref: {
    bpo: '',
    adm: '',
    auth: '',
    store: '',
    home: '',
  },
  sysHrefMap: new Map(),
  user: {},
  entity: {},
  instance: {},
  roots: [],
  systems: [],
})

const actions = {
  async recoverSession({ commit }) {
    commit('REMEMBER_STATE_RECOVER')
    await apiGetData('auth/login/get-active-session').then((response) => {
      commit('LOAD_SYS_HREF', response)
      if ('sessionState' in response && response.sessionState) {
        commit('LOAD_SESSION', response)
        commit('INIT_SESSION')
        //router.push('/')
      } else {
        commit('END_SESSION')
      }
    })
  },

  setRememberState({ commit }, state) {
    commit('SET_REMEMBER_STATE', state)
  },

  logout({ commit }) {
    apiGetData('auth/login/revoke')
    commit('END_SESSION')
  },

  endSession({ commit }) {
    commit('END_SESSION')
  },
}

const mutations = {
  LOAD_SYS_HREF(state, response) {
    state.sysHref = response.sysHref
    for (const sys in state.sysHref) {
      state.sysHrefMap.set(state.sysHref[sys], sys)
    }
  },

  LOAD_SESSION(state, response) {
    state.user = response.user
    state.entity = response.entity
    state.instance = response.instance
    state.roots = response.roots
    state.systems = response.systems
    localStorage.setItem('user', JSON.stringify(response.user))
  },

  INIT_SESSION(state) {
    state.auth = true
  },

  END_SESSION(state) {
    if (!state.rememberMe) {
      localStorage.removeItem('user')
    }
    state.auth = false

    // let domain = `${window.location.protocol}//${window.location.hostname}`
    // if (window.location.port != '') {
    //   domain = `${domain}:${window.location.port}`
    // }
    // let sys = state.sysHrefMap.get(domain)

    // const buffer = Buffer.from(`${sys}:/${window.location.pathname}`)
    // window.location.replace(
    //   `${state.sysHref.auth}/users/login/${buffer.toString('base64')}`
    // )
  },

  SET_REMEMBER_STATE(state, status) {
    state.rememberMe = status
    localStorage.setItem('rememberMe', status)
  },

  REMEMBER_STATE_RECOVER(state) {
    try {
      state.rememberMe = JSON.parse(localStorage.getItem('rememberMe'))

      state.user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : {}
    } catch (e) {
      state.rememberMe = false
      state.user = {}
    }
  },
}

const getters = {
  getSessionState: (state) => {
    return state
  },
  getAuthStatus: (state) => {
    return state.auth
  },
  getRememberMeState: (state) => {
    return state.rememberMe
  },
  getUser: (state) => {
    return state.user
  },
  getEntity: (state) => {
    return state.entity
  },
  getInstance: (state) => {
    return state.instance
  },
  getSysHref: (state) => {
    return state.sysHref
  },
  getRoots: (state) => {
    return state.roots
  },
  checkRoot: (state) => (root) => {
    return (
      state.roots.filter((segment) => {
        return segment == root
      }).length > 0
    )
  },
  checkRoots: (state, getters) => (roots) => {
    let access = false
    roots.forEach(function (element) {
      if (getters.checkRoot(element)) {
        access = true
      }
    })
    return access
  },
  getSystems: (state) => {
    return state.systems
  },
  checkSystems: (state) => (system) => {
    return (
      state.systems.filter((candidate) => {
        return candidate == system
      }).length > 0
    )
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
