const state = () => ({
  notificationsQueue: [],
})

const actions = {
  addNotification({ commit, dispatch }, notification) {
    notification.id = (
      Math.random().toString(36) + Date.now().toString(36)
    ).substring(2)

    commit('ADD_NOTIFICATION', notification)
    setTimeout(() => {
      dispatch('deleteNotification', notification.id)
    }, notification.ttl)
  },
  addNotifications({ dispatch }, notifications) {
    notifications.forEach(function (notification) {
      dispatch('addNotification', notification)
    })
  },
  deleteNotification({ commit }, notificationId) {
    commit('DELETE_NOTIFICATION', notificationId)
  },
}

const mutations = {
  ADD_NOTIFICATION(state, notification) {
    if (
      state.notificationsQueue.filter((currentNotification) => {
        return currentNotification.message == notification.message
      }).length === 0
    ) {
      state.notificationsQueue.push(notification)
    }
  },

  DELETE_NOTIFICATION(state, notificationId) {
    state.notificationsQueue = state.notificationsQueue.filter(
      (notification) => {
        return notification.id != notificationId
      }
    )
  },
}

const getters = {
  getNotificationsQueue: (state) => {
    return state.notificationsQueue
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
